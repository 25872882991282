var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"CommandQuery"},[_c('el-header',{attrs:{"height":"auto"}},[_c('div',{staticClass:"filter-pane reportTree"},[_c('label',[_vm._v("车组车辆")]),_c('SelectGroupTree',{staticClass:"TreeWidth",attrs:{"multipleProp":{
          noGroup: true, // 是否可选车组
          groupCheckBox: false, //车组多选
          vehicleCheckBox: false, //车辆多选
        }},on:{"on-select":_vm.onSelect,"on-vehicle":_vm.onConfirm,"dbClick":_vm.dbConfirm,"clear":_vm.ClearSearchParams}}),_c('label',[_vm._v("选择时间")]),_c('reportDate',{attrs:{"size":"small"},on:{"change":_vm.onChoiceDate}}),_c('el-button',{staticStyle:{"font-size":"14px","width":"90px","height":"32px","margin-left":"15px"},attrs:{"size":"mini","type":"primary","disabled":_vm.tableLoading},on:{"click":function($event){return _vm.onSearchClick(1)}}},[_vm._v("搜索")])],1),_c('div',{staticClass:"oper-btns"},[_c('BtnExport',{attrs:{"disabled":!_vm.data>0,"title":"指令查询","exportUrl":"exportReport/exportExcel.do","exportDataType":"1","params":{
          ...this.searchParams,
          type:16,
          pageSize:5000,
        }}})],1)]),_c('el-main',{staticClass:"reportTable"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"height":"calc(100% - 45px)","border":"","size":"mini","data":_vm.data}},[_c('el-table-column',{attrs:{"type":"index","width":"70","label":"序号"}}),_c('el-table-column',{attrs:{"prop":"userName","width":"120","label":"下发用户"}}),_c('el-table-column',{attrs:{"prop":"plate","width":"120","label":"设备名称"}}),_c('el-table-column',{attrs:{"prop":"plateColor","width":"120","label":"车牌颜色"}}),_c('el-table-column',{attrs:{"prop":"terminalNo","width":"180","label":"终端编号"}}),_c('el-table-column',{attrs:{"prop":"terminalType","width":"120","label":"终端类型"}}),_c('el-table-column',{attrs:{"prop":"groupName","label":"所属车组","width":"120"}}),_c('el-table-column',{attrs:{"prop":"commandId","width":"120","label":"下发类型"}}),_c('el-table-column',{attrs:{"prop":"commandMsg","width":"450","label":"下发内容"}}),_c('el-table-column',{attrs:{"prop":"createTime","width":"180","label":"下发时间"}}),_c('el-table-column',{attrs:{"prop":"result","min-width":"80","label":"下发结果"}})],1),_c('el-pagination',{staticStyle:{"text-align":"center","margin":"10px 10px 0 10px"},attrs:{"background":"","current-page":_vm.tablePage.pageNumber,"page-size":_vm.tablePage.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.tablePage.total},on:{"current-change":_vm.changePage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }