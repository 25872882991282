<template>
  <!-- <div>指令查询</div> -->
  <el-container class="CommandQuery">
    <el-header height="auto">
      <div class="filter-pane reportTree">
        <label>车组车辆</label>
        <SelectGroupTree @on-select="onSelect"
          @on-vehicle="onConfirm"
          @dbClick="dbConfirm"
          :multipleProp="{
            noGroup: true, // 是否可选车组
            groupCheckBox: false, //车组多选
            vehicleCheckBox: false, //车辆多选
          }"
          class="TreeWidth"
          @clear="ClearSearchParams"></SelectGroupTree>

        <label>选择时间</label>
        <reportDate size="small"
          @change="onChoiceDate"></reportDate>

        <el-button size="mini"
          type="primary"
          style="font-size: 14px;width:90px;height:32px;margin-left:15px;"
          :disabled="tableLoading"
          @click="onSearchClick(1)">搜索</el-button>
      </div>
      <div class="oper-btns">
        <BtnExport :disabled="!data>0"
          title="指令查询"
          exportUrl="exportReport/exportExcel.do"
          exportDataType="1"
          :params="{
            ...this.searchParams,
            type:16,
            pageSize:5000,
          }" />
      </div>
    </el-header>
    <el-main class="reportTable">
      <el-table v-loading="tableLoading"
        height="calc(100% - 45px)"
        border
        size="mini"
        :data="data">
        <el-table-column type="index"
          width="70"
          label="序号"></el-table-column>
        <el-table-column prop="userName"
          width="120"
          label="下发用户"></el-table-column>
        <el-table-column prop="plate"
          width="120"
          label="设备名称"></el-table-column>
        <el-table-column prop="plateColor"
          width="120"
          label="车牌颜色"></el-table-column>
        <el-table-column prop="terminalNo"
          width="180"
          label="终端编号"></el-table-column>
        <el-table-column prop="terminalType"
          width="120"
          label="终端类型"></el-table-column>
        <el-table-column prop="groupName"
          label="所属车组"
          width="120"></el-table-column>
        <el-table-column prop="commandId"
          width="120"
          label="下发类型"></el-table-column>
        <el-table-column prop="commandMsg"
          width="450"
          label="下发内容"></el-table-column>
        <el-table-column prop="createTime"
          width="180"
          label="下发时间"></el-table-column>
        <el-table-column prop="result"
          min-width="80"
          label="下发结果"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background
        :current-page="tablePage.pageNumber"
        :page-size="tablePage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tablePage.total"
        @current-change="changePage"
        style="text-align: center; margin: 10px 10px 0 10px;"></el-pagination>
    </el-main>
  </el-container>
</template>

<script>
//选择时间
import reportDate from "@/components/Date/reportDate";
//选择车组
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
//搜索接口
import { getCommandReport } from '@/api/All-Interface.js';
//导出组件
import BtnExport from "@/components/Export";
export default {
  // `name`与路由保持一致
  name: 'CommandQuery',
  components: {
    reportDate,
    SelectGroupTree,
    BtnExport
  },
  data() {
    return {
      searchParams: {
        vehicleId: 0,
        groupId: 0,
        flag: 0,
        startTime: '',
        endTime: '',
        pageSize: 20,
        pageNumber: 1,
      },
      data: [],//数据集合
      tableLoading: false,//用于加载数据时的等待状态
      tablePage: {
        //分页
        pageNumber: 1,
        pageSize: 20,
        total: 0
      },
    };
  },
  methods: {
    onChoiceDate(dates) {//选择时间
      this.searchParams.startTime = '';
      this.searchParams.endTime = '';
      if (dates.length === 0) return this.$message.warning('请选择正确的时间');
      if (dates.length === 2) {
        this.searchParams.startTime = dates[0];
        this.searchParams.endTime = dates[1];
      }
    },
    //车组车辆搜索框选择
    onSelect(node) {
      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.groupId = node.groupId || 0;
      if (node.type == 2) {
        this.searchParams.flag = 1;
      } else if (node.type == 1) {
        this.searchParams.flag = 0;
      }
    },
    // 选择车辆事件
    onConfirm(node) {
      //先进行清空
      this.searchParams.vehicleId = 0;
      this.searchParams.groupId = 0;

      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.flag = 1;
      this.searchParams.groupId = node.groupId || 0;
    },
    // 选择车组
    dbConfirm(node) {
      this.searchParams.pageNumber = 1;//重新选择车组车辆时间时，页数重置
      this.searchParams.flag = 0;
      this.searchParams.groupId = node.groupId;
      this.searchParams.vehicleId = 0;
    },
    //点击清除时，初始化车组车辆参数
    ClearSearchParams() {
      this.searchParams.vehicleId = 0;
      this.searchParams.groupId = 0;
    },
    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.searchParams.pageNumber = current;
      this.onSearchClick(current);
    },
    //验证是否有输入车组或车辆
    verification() {
      if (this.searchParams.groupId == "" || this.searchParams.groupId == 0) {
        this.$message.info("请输入或者选择车组车辆");
        return false;
      } else {
        return true;
      };
    },
    //点击搜索
    onSearchClick(current) {
      this.data = [];
      if (!this.verification()) return;//验证是否有输入车组或车辆
      this.tableLoading = true;//开启等待转圈圈
      getCommandReport({
        ...this.searchParams,
        pageNumber: current
      }).then((res) => {
        this.tableLoading = false;//关闭等待转圈圈
        if (res.flag == 1) {
          if (res.rows.length == 0) return this.$message.warning("数据为空");
          this.data = res.rows;
          this.tablePage.total = res.total;
          this.tablePage.pageNumber = current;
          this.searchParams.pageNumber = current;
        } else {
          return this.$message.warning("数据为空！");
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
@import 'src/style/reportTreeAndData.scss';
.CommandQuery {
  height: 100%;
  .el-header {
    margin: 0;
    padding: 0;
    .oper-btns {
      bottom: 8px;
      right: 15px;
    }
  }
  .el-main {
    height: calc(100% - 48px) !important;
    padding: 10px 20px 0 20px;
  }
  .el-dialog__header {
    background-color: #212d3e !important;
  }
  .pagination-pane {
    text-align: center;
  }
  .TreeWidth {
    ::v-deep .inline-input {
      width: 220px;
    }
  }
}
</style>